/* @import "~@angular/material/prebuilt-themes/deeppurple-amber.css";*/
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
/* @import "~@angular/material/prebuilt-themes/pink-bluegrey"; */
/* @import "~@angular/material/prebuilt-themes/purple-green.css"; */

/* You can add global styles to this file, and also import other style files */

:host {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 14px;
  color: #333;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Interしか使わないなら、以下を用いても良い */
@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter/Inter-VariableFont_slnt,wght.ttf');
}
body {
  font-family: Inter !important;
}

/* toolbarコンポーネントの位置指定 */
/* floor-headerを使う場合は使用しなくていい */
.toolbar-only {
  top: 0;
  position: sticky;
  z-index: 999;
}

/* angular material(mat-menuなど)を書き換える手段がこれしかない */
.mat-menu-panel {
  display: flex;
  max-width: 500px !important;
  border: 2px solid #1e1e1e !important;
  border-radius: 8px;
  width: 300px;
  background: #000000cc 0% 0% no-repeat padding-box;
}

.mat-menu-content {
  width: 100%;
  /* padding: 0 5%; */
}

/* mat-form-field mat-select関連 */
.mat-form-field-underline {
  display: none !important;
}

.mat-form-field-infix {
  width: 18rem !important;
  border-top: none !important;
}

.mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

/* .mat-select {
  width: 90% !important;
} */

.mat-select-panel {
  border-radius: 8px !important; /* ドロップダウンの角丸を指定 */
  width: 18rem !important;
}

/* sliderの色変更がかなりめんどくさい */
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-track-background {
  background-color: #000000cc !important;
}

.mat-accent .mat-slider-thumb {
  background-color: #0caccd !important;
}

.mat-accent .mat-slider-thumb-label {
  background-color: #0caccd !important;
}

.mat-accent .mat-slider-track-fill {
  background-color: #000000cc !important;
}

.mat-tooltip {
  background-color: #000000cc;
  overflow: hidden;
}
